import { configureStore } from "@reduxjs/toolkit";
import drawReducer from "../modules/draw";
import statisticsReducer from "../modules/statistics";
const store = configureStore({
    reducer:{
        draw:drawReducer,
        statistics:statisticsReducer,
    }
})

export default store;