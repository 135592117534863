import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './index.scss';
import { useEffect, useState } from 'react';

// 配置对象
const headerConfig = {
    '/history': {
        title: '往期数据',
        showBackButton: true,
    },
    '/statistics': {
        title: '数据统计',
        showBackButton: true,
    },
    '/lotteryImage': {
        title: '相关资料',
        showBackButton: true,
    },
    '/detail/*': {
        title: '内部资料',
        showBackButton: true
    }
    // 其他路径的配置
};

const Layout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    // 查找与当前路径匹配的配置
    let currentConfig = headerConfig[pathname];
    if (!currentConfig) {
        for (const key in headerConfig) {
            if (new RegExp(`^${key.replace('*', '.*')}$`).test(pathname)) {
                currentConfig = headerConfig[key];
                break;
            }
        }
    }

    currentConfig = currentConfig || { title:'', showBackButton: false };

    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="layout" style={{ maxWidth: containerWidth-20}}>
            <div className='header'>
                {currentConfig.showBackButton && (
                    <span className="back-button" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </span>
                )}
                <span>{currentConfig.title}</span>
                <span className="menu-button">
                </span>
            </div>
            <div className="container">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
