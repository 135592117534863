import React, { useEffect, useState } from 'react';
import './lottery.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLast } from '../redux/modules/draw';
import { SpinLoading } from 'antd-mobile';

const Lottery = () => {
  const dispatch = useDispatch();
  const lastDraw = useSelector((state) => state.draw.lastDraw);
  const nextDrawTime = useSelector((state) => state.draw.nextDrawTime);

  const [visibleNumbers, setVisibleNumbers] = useState(0);
  const [statusText, setStatusText] = useState("");
  useEffect(() => {
    dispatch(getLast());
    const interval = setInterval(() => {
      dispatch(getLast());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    let countdownInterval;
    if (nextDrawTime) {
      const nextDrawDate = new Date(nextDrawTime);
      // Override nextDrawDate for testing - set to 5 minutes from now
      const internalDrawDate = new Date(nextDrawDate.getTime() + 3 * 60 * 1000); // 3 minutes slower
      const updateStatus = () => {
        const currentTime = new Date();
        const timeDifference = Math.floor((internalDrawDate - currentTime) / 1000); // in seconds

        if (timeDifference < 0) {
          clearInterval(countdownInterval);
          setStatusText(`第${lastDraw.data.id}期 ${lastDraw.data.drawDate}`);
        }
        else if (timeDifference <= 180) { // Less than or equal to 3 minutes
          setStatusText("正在开奖");
          const revealIntervals = [160, 140, 120, 100, 80, 60]; // Times to reveal numbers

          for (let i = 0; i < revealIntervals.length; i++) {
            if (timeDifference <= revealIntervals[i]) {
              setVisibleNumbers(i + 1);
            }
          }
        } else if (timeDifference <= 480) { // Less than or equal to 8 minutes
          setStatusText("正在准备开奖");
          setVisibleNumbers(0); // Hide all numbers
        } else {
          setStatusText("");
          setVisibleNumbers(6); // Show all numbers
        }
      };

      updateStatus(); // Initial call
      countdownInterval = setInterval(updateStatus, 1000); // Check every second
    }

    return () => clearInterval(countdownInterval);
  }, [nextDrawTime]);

  const getBackgroundClass = (number) => {
    const redNumbers = [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46];
    const blueNumbers = [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48];
    if (redNumbers.includes(number)) {
      return 'red';
    } else if (blueNumbers.includes(number)) {
      return 'blue';
    } else {
      return 'green';
    }
  };

  if (!lastDraw || !lastDraw.data) {
    return (
      <div className="loading-container">
        <SpinLoading color="default" />
      </div>
    );
  }

  const numbers = [
    { value: lastDraw.data.number1, label: lastDraw.data.number1Zodiac },
    { value: lastDraw.data.number2, label: lastDraw.data.number2Zodiac },
    { value: lastDraw.data.number3, label: lastDraw.data.number3Zodiac },
    { value: lastDraw.data.number4, label: lastDraw.data.number4Zodiac },
    { value: lastDraw.data.number5, label: lastDraw.data.number5Zodiac },
    { value: lastDraw.data.number6, label: lastDraw.data.number6Zodiac }
  ];

  const extraNumber = {
    value: lastDraw.data.luckyNumber,
    label: lastDraw.data.luckyNumberZodiac
  };

  return (
    <div className="lottery">
      <div className="lottery-header">
        <span>美彩第{lastDraw.data.id}期</span>
      </div>
      <div className="lottery-numbers">
        {numbers.slice(0, visibleNumbers).map((num, index) => (
          <div key={index} className={`number number-${index + 1} ${getBackgroundClass(num.value)}`}>
            <span>{num.value}</span>
            <div className="label">{num.label}</div>
          </div>
        ))}
        {visibleNumbers > 0 && visibleNumbers <= 6 && <div className="plus">+</div>}
        {visibleNumbers === 6 && (
          <div className={`number extra-number ${getBackgroundClass(extraNumber.value)}`}>
            <span>{extraNumber.value}</span>
            <div className="label">{extraNumber.label}</div>
          </div>
        )}
      </div>
      <div className="lottery-footer">
        {statusText || `第${lastDraw.data.id}期 ${lastDraw.data.drawDate}`}
      </div>
    </div>
  );
};

export default Lottery;
