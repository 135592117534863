import React, { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { DatePicker, Space } from 'antd-mobile';
import { getStatistics } from '../../redux/modules/statistics';
import { useDispatch, useSelector } from 'react-redux';

const Statistics = () => {
  const dispatch = useDispatch();
  const fetchedStatistics = useSelector((state) => state.statistics);
  const [statistics, setStatistics] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startPickerVisible, setStartPickerVisible] = useState(false);
  const [endPickerVisible, setEndPickerVisible] = useState(false);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      dispatch(getStatistics(dateRange[0].toISOString().split('T')[0], dateRange[1].toISOString().split('T')[0]));
    }
  }, [dateRange, dispatch]);

  useEffect(() => {
    if (fetchedStatistics) {
      const mappedStatistics = [
        {
          title: "特码出现期数最多的号码",
          numbers: fetchedStatistics.topLuckyNumbers.map(item => item.value),
        },
        {
          title: "特码当前遗漏期数最多的号码",
          numbers: fetchedStatistics.bottomLuckyNumbers.map(item => item.value),
        },
        {
          title: "正码出现期数最多的号码",
          numbers: fetchedStatistics.topNormalNumbers.map(item => item.value),
        },
        {
          title: "正码当前遗漏期数最多的号码",
          numbers: fetchedStatistics.bottomNormalNumbers.map(item => item.value),
        },
        {
          title: "特码出现期数最多的生肖",
          numbers: fetchedStatistics.topLuckyZodiacs.map(item => item.value),
        },
        {
          title: "特码当前遗漏期数最多的生肖",
          numbers: fetchedStatistics.bottomLuckyZodiacs.map(item => item.value),
        },
        {
          title: "正码出现期数最多的生肖",
          numbers: fetchedStatistics.topNormalZodiacs.map(item => item.value),
        },
        {
          title: "正码当前遗漏期数最多的生肖",
          numbers: fetchedStatistics.bottomNormalZodiacs.map(item => item.value),
        },
      ];
      setStatistics(mappedStatistics);
    }
  }, [fetchedStatistics]);

  const getColorClass = (number) => {
    const redNumbers = [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46];
    const blueNumbers = [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48];
    const greenNumbers = [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44,49];
    if (redNumbers.includes(parseInt(number))) {
      return 'red';
    } else if (blueNumbers.includes(parseInt(number))) {
      return 'blue';
    } else if (greenNumbers.includes(parseInt(number))) {
      return 'green';
    } else {
      return '';
    }
  };

  const handleDateChange = (index, value) => {
    if (value) {
      const newDateRange = [...dateRange];
      newDateRange[index] = new Date(value);
      setDateRange(newDateRange);
      console.log('Selected Date Range:', newDateRange);
    }
  };

  return (
    <div className="statistics">
      <div className="datepick">
        <Space direction="horizontal" size={12}>
          <Button onClick={() => setStartPickerVisible(true)}>
            {dateRange[0] ? `${dateRange[0].toLocaleDateString()}` : '开始日期'}
          </Button>
          <Button onClick={() => setEndPickerVisible(true)}>
            {dateRange[1] ? `${dateRange[1].toLocaleDateString()}` : '结束日期'}
          </Button>
        </Space>
        <Button className="searchbutton" type="primary" onClick={() => dispatch(getStatistics(dateRange[0].toISOString().split('T')[0], dateRange[1].toISOString().split('T')[0]))}>
          查询
        </Button>
      </div>

      <DatePicker
        visible={startPickerVisible}
        onClose={() => setStartPickerVisible(false)}
        onConfirm={(value) => {
          handleDateChange(0, value);
          setStartPickerVisible(false);
        }}
        precision="day"
        max={new Date()}
        title="开始日期"
      />

      <DatePicker
        visible={endPickerVisible}
        onClose={() => setEndPickerVisible(false)}
        onConfirm={(value) => {
          handleDateChange(1, value);
          setEndPickerVisible(false);
        }}
        precision="day"
        max={new Date()}
        title="结束日期"
      />

      <div className="content">
        {statistics.map((stat, index) => (
          <div key={index} className="stat-section">
            <div className="title">{stat.title}</div>
            <div className="numbers">
              {stat.numbers.map((num, idx) => (
                <div key={idx} className={`number ${getColorClass(num)}`}>
                  <span>{num}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
