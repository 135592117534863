import Layout from "../pages/Layout";
import Homepage from "../pages/Homepage";
import History from "../pages/LotteryHistory";
import Statistics from "../pages/statisticsPage";
import LotteryImage from "../pages/LotteryImage";
import DetailPage from "../pages/DetailedPage";
const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
    {
        path:'/',
        element:<Layout/>,
        children:[
            {
                path:'/',
                element:<Homepage/>
            },
            {
                path:'/history',
                element:<History/>
            },
            {
                path:'/statistics',
                element:<Statistics/>
            },
            {
                path:'/lotteryImage',
                element:<LotteryImage/>
            },
            {
                path:"/detail/:category/:index",
                element:<DetailPage />
            }
        ],
    }
]);

export default router;
