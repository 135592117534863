import React, { useEffect, useState } from 'react';
import './CountDownFlip.scss';

class Flipper {
  constructor(node, currentTime, nextTime) {
    this.isFlipping = false;
    this.duration = 600;
    this.flipNode = node;
    this.frontNode = node.querySelector(".front");
    this.backNode = node.querySelector(".back");
    this.setFrontTime(currentTime);
    this.setBackTime(nextTime);
  }

  setFrontTime(time) {
    this.frontNode.dataset.number = time;
  }

  setBackTime(time) {
    this.backNode.dataset.number = time;
  }

  flipDown(currentTime, nextTime) {
    if (this.isFlipping) {
      return false;
    }
    this.isFlipping = true;
    this.setFrontTime(currentTime);
    this.setBackTime(nextTime);
    this.flipNode.classList.add("running");
    setTimeout(() => {
      this.flipNode.classList.remove("running");
      this.isFlipping = false;
      this.setFrontTime(nextTime);
    }, this.duration);
  }
}

const getTimeFromSeconds = (seconds) => {
  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
  const secs = String(seconds % 60).padStart(2, '0');
  return `${hours}${minutes}${secs}`;
};

const CountDownFlip = ({ initialTime }) => {
  const [remainingTime, setRemainingTime] = useState(initialTime);

  useEffect(() => {
    const flips = document.querySelectorAll(".flip");
    const initialTimeStr = getTimeFromSeconds(remainingTime);
    const flippersArray = Array.from(flips).map(
      (flip, i) => new Flipper(flip, initialTimeStr[i], initialTimeStr[i])
    );

    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(interval);
          return 0;
        }
        const newTime = prevTime - 1;
        const currentTimeStr = getTimeFromSeconds(prevTime);
        const nextTimeStr = getTimeFromSeconds(newTime);
        for (let i = 0; i < flippersArray.length; i++) {
          if (currentTimeStr[i] === nextTimeStr[i]) {
            continue;
          }
          flippersArray[i].flipDown(currentTimeStr[i], nextTimeStr[i]);
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  return (
    <div className="clock">
      <div className="flip">
        <div className="digital front" data-number="0"></div>
        <div className="digital back" data-number="1"></div>
      </div>
      <div className="flip">
        <div className="digital front" data-number="0"></div>
        <div className="digital back" data-number="1"></div>
      </div>
      <em className="divider">:</em>
      <div className="flip">
        <div className="digital front" data-number="0"></div>
        <div className="digital back" data-number="1"></div>
      </div>
      <div className="flip">
        <div className="digital front" data-number="0"></div>
        <div className="digital back" data-number="1"></div>
      </div>
      <em className="divider">:</em>
      <div className="flip">
        <div className="digital front" data-number="0"></div>
        <div className="digital back" data-number="1"></div>
      </div>
      <div className="flip">
        <div className="digital front" data-number="0"></div>
        <div className="digital back" data-number="1"></div>
      </div>
    </div>
  );
};

export default CountDownFlip;
