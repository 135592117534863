import { createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios";

const statistics = createSlice({
    name: 'statistics',
    initialState: {
        topLuckyNumbers: [],
        topLuckyZodiacs: [],
        bottomLuckyNumbers: [],
        bottomLuckyZodiacs: [],
        topNormalNumbers: [],
        topNormalZodiacs: [],
        bottomNormalNumbers: [],
        bottomNormalZodiacs: []
    },
    reducers: {
        setTopLuckyNumbers(state, action) {
            state.topLuckyNumbers = action.payload;
        },
        setTopLuckyZodiacs(state, action) {
            state.topLuckyZodiacs = action.payload;
        },
        setBottomLuckyNumbers(state, action) {
            state.bottomLuckyNumbers = action.payload;
        },
        setBottomLuckyZodiacs(state, action) {
            state.bottomLuckyZodiacs = action.payload;
        },
        setTopNormalNumbers(state, action) {
            state.topNormalNumbers = action.payload;
        },
        setTopNormalZodiacs(state, action) {
            state.topNormalZodiacs = action.payload;
        },
        setBottomNormalNumbers(state, action) {
            state.bottomNormalNumbers = action.payload;
        },
        setBottomNormalZodiacs(state, action) {
            state.bottomNormalZodiacs = action.payload;
        }
    }
});

const {
    setTopLuckyNumbers,
    setTopLuckyZodiacs,
    setBottomLuckyNumbers,
    setBottomLuckyZodiacs,
    setTopNormalNumbers,
    setTopNormalZodiacs,
    setBottomNormalNumbers,
    setBottomNormalZodiacs
} = statistics.actions;

const getStatistics = (startDate, endDate) => async (dispatch) => {
    try {
        const res = await request.get(`/api/draws/public/range`, {
            params: { startDate, endDate }
        });
        const data = res.data;
        dispatch(setTopLuckyNumbers(data.topLuckyNumbers));
        dispatch(setTopLuckyZodiacs(data.topLuckyZodiacs));
        dispatch(setBottomLuckyNumbers(data.bottomLuckyNumbers));
        dispatch(setBottomLuckyZodiacs(data.bottomLuckyZodiacs));
        dispatch(setTopNormalNumbers(data.topNormalNumbers));
        dispatch(setTopNormalZodiacs(data.topNormalZodiacs));
        dispatch(setBottomNormalNumbers(data.bottomNormalNumbers));
        dispatch(setBottomNormalZodiacs(data.bottomNormalZodiacs));
    } catch (error) {
        console.error('Error fetching statistics:', error);
    }
};

export { getStatistics };

const statisticsReducer = statistics.reducer;
export default statisticsReducer;
