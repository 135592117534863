import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDraw } from '../../redux/modules/draw';
import styles from './index.module.scss'; // 使用CSS模块

const History = () => {
  const dispatch = useDispatch();
  const drawHistory = useSelector(state => state.draw.drawList);
  const currentPage = useSelector(state => state.draw.currentPage);
  const hasMore = useSelector(state => state.draw.hasMore);
  const observer = useRef();

  useEffect(() => {
    dispatch(getDraw(0)); 
  }, [dispatch]);

  const lastDrawElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        dispatch(getDraw(currentPage + 1)); 
      }
    });
    if (node) observer.current.observe(node);
  }, [dispatch, hasMore, currentPage]);

  const getColorClass = (number) => {
    const redNumbers = [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46];
    const blueNumbers = [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48];
    if (redNumbers.includes(number)) {
      return styles.red;
    } else if (blueNumbers.includes(number)) {
      return styles.blue;
    } else {
      return styles.green;
    }
  };

  if (!Array.isArray(drawHistory)) {
    return <div>Loading...</div>; 
  }

  return (
    <div className={styles.history}>
      <div className={styles.drawList}>
        {drawHistory.map((draw, index) => {
          if (drawHistory.length === index + 1) {
            return (
              <div ref={lastDrawElementRef} key={index} className={styles.drawItem}>
                <div className={styles.drawHeader}>
                  <span>美彩第{draw.id}期开奖结果</span>
                  <span className={styles.drawDate}>{draw.drawDate}</span>
                </div>
                <div className={styles.drawNumbers}>
                  {[draw.number1, draw.number2, draw.number3, draw.number4, draw.number5, draw.number6].map((num, idx) => (
                    <div key={idx} className={`${styles.number} ${getColorClass(num)}`}>
                      <span>{num}</span>
                      <div className={styles.label}>{draw[`number${idx + 1}Zodiac`]}</div>
                    </div>
                  ))}
                  <div className={styles.plus}>+</div>
                  <div className={`${styles.number} ${styles.extraNumber} ${getColorClass(draw.luckyNumber)}`}>
                    <span>{draw.luckyNumber}</span>
                    <div className={styles.label}>{draw.luckyNumberZodiac}</div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className={styles.drawItem}>
                <div className={styles.drawHeader}>
                  <span>第{draw.id}期开奖结果</span>
                  <span className={styles.drawDate}>{draw.drawDate}</span>
                </div>
                <div className={styles.drawNumbers}>
                  {[draw.number1, draw.number2, draw.number3, draw.number4, draw.number5, draw.number6].map((num, idx) => (
                    <div key={idx} className={`${styles.number} ${getColorClass(num)}`}>
                      <span>{num}</span>
                      <div className={styles.label}>{draw[`number${idx + 1}Zodiac`]}</div>
                    </div>
                  ))}
                  <div className={styles.plus}>+</div>
                  <div className={`${styles.number} ${styles.extraNumber} ${getColorClass(draw.luckyNumber)}`}>
                    <span>{draw.luckyNumber}</span>
                    <div className={styles.label}>{draw.luckyNumberZodiac}</div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default History;
