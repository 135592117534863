import React from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LotteryImage = () => {
  const navigate = useNavigate();
  const lastDraw = useSelector((state) => state.draw.lastDraw);
  const currentPeriod = lastDraw?.data?.id || 0;

  const images = [
    { src:require('../../assets/images/blxmj.jpg'), description: '伯乐相马', path: 'blxm' },
    { src:require('../../assets/images/sbx.jpg'), description: '四不像', path: 'sbx' },
    { src:require('../../assets/images/zdr.jpg'), description: '六合堂', path: 'lht' },
    { src:require('../../assets/images/yxzt.jpg'), description: '一肖中特', path: 'yxzt' },
    { src:require('../../assets/images/cslx.jpg'), description: '财神六肖', path: 'cslx' },
    { src:require('../../assets/images/snt.jpg'), description: '神鸟图', path: 'snt' },
    { src:require('../../assets/images/cscs.jpg'), description: '吃啥出啥', path: 'cscs' },
    { src:require('../../assets/images/jjj.jpg'), description: '九九九', path: 'jjj' },
    { src:require('../../assets/images/sgzb.jpg'), description: '三国争霸', path: 'sgzb' },
    { src:require('../../assets/images/lhst.jpg'), description: '六合神童', path: 'lhst' },
    { src:require('../../assets/images/bzwxt.jpg'), description: '本站五肖图', path: 'bzwxt' },
    { src:require('../../assets/images/byss.jpg'), description: '布衣神算', path: 'byss' },
    { src:require('../../assets/images/sgnn.jpg'), description: '水果奶奶', path: 'sgnn' },
    { src:require('../../assets/images/jhl.jpg'), description: '江湖令', path: 'jhl' },
    { src:require('../../assets/images/zcm.jpg'), description: '招财猫', path: 'zcm' },
    { src:require('../../assets/images/lhfy.jpg'), description: '六合风云', path: 'lhfy' },
    { src:require('../../assets/images/fhtt.jpg'), description: '凤凰图腾', path: 'fhtt' },
    { src:require('../../assets/images/jxsbm.jpg'), description: '九肖18码', path: 'jxsbm' },
    { src:require('../../assets/images/zclm.jpg'), description: '早茶论码', path: 'zclm' },
    { src:require('../../assets/images/wzs.jpg'), description: '五指山', path: 'wzs' },
    { src:require('../../assets/images/lxw.jpg'), description: '连肖王', path: 'lxw' },
    { src:require('../../assets/images/xkjyx.jpg'), description: '侠客禁一肖', path: 'xkjyx' },
    { src:require('../../assets/images/ptyx.jpg'), description: '平特一肖', path: 'ptyx' },
    { src:require('../../assets/images/ymzt.jpg'), description: '一码中特', path: 'ymzt' },
    { src:require('../../assets/images/tzgp.jpg'), description: '童子挂牌', path: 'tzgp' },
    { src:require('../../assets/images/jxsm.jpg'), description: '九肖十码', path: 'jxsm' },
    { src:require('../../assets/images/pgt.jpg'), description: '跑狗图', path: 'pgt' },
    { src:require('../../assets/images/jsz.jpg'), description: '金手指', path: 'jsz' },
  ];

  
  const handleClick = (path) => {
    const index = currentPeriod - 1;
    navigate(`/detail/${path}/${index}`);
  };

  return (
    <div className="lottery-image-page">
      {images.map((image) => (
        <div key={image.path} className="image-container" onClick={() => handleClick(image.path)}>
          <div className="image" style={{ backgroundImage: `url(${image.src})` }}>
          </div>
          <div className="description">{image.description}</div>
        </div>
      ))}
    </div>
  );
};

export default LotteryImage;
