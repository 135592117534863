import { createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios";
const draw = createSlice({
    name:'draw',
    initialState:{
        drawList:[],
        currentPage:0,
        hasMore:true,
        lastDraw:[],
        nextDrawTime:null,
    },
    reducers:{
        setDraw(state,action){
            if(action.payload.page === 0){
            state.drawList = action.payload.data;
        }else{
            state.drawList = [...state.drawList,...action.payload.data];
        }
        state.currentPage = action.payload.page;
        state.hasMore = action.payload.hasMore;
        },
        setLastDraw(state,action){
            state.lastDraw = action.payload;
        },
        setNextDrawTime(state,action){
            state.nextDrawTime = action.payload;
        }
    }
})

const{setDraw,setLastDraw,setNextDrawTime} = draw.actions;

const getDraw = (page = 0, size = 10) => async (dispatch) => {
    try {
      const res = await request.get(`/api/draws/getAll?page=${page}&size=${size}`);
      dispatch(setDraw({ data: res.data.data.content, page, hasMore: res.data.data.content.length === size }));
    } catch (error) {
      console.error('Error fetching draw history:', error);
    }
  };

const getLast = () => async(dispatch)=>{
    try{
        const res = await request.get("/api/draws/latest");
        dispatch(setLastDraw(res.data));
    }catch(error)
    {
        return error;
    }
}

const getNextDrawTime = () => async (dispatch) => {
    try {
      const res = await request.get("/api/draws/nextDrawTime");
      // 移除不兼容的时区信息
      const dateString = res.data.data.replace(/\[.*\]$/, '');
      const nextDrawTime = new Date(dateString); // 解析时间字符串为 Date 对象
      if (isNaN(nextDrawTime)) {
        throw new Error('Invalid date format');
      }
      dispatch(setNextDrawTime(nextDrawTime.toISOString()));
    } catch (error) {
      console.error('Error fetching next draw time:', error);
    }
  };
export {getDraw,getLast,getNextDrawTime};

const drawReducer = draw.reducer;
export default drawReducer;