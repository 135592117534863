import React, { useEffect, useState } from 'react';
import './index.scss';
import { Tabs, SpinLoading } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import { getLast } from '../../redux/modules/draw';
import { useNavigate, useParams } from 'react-router-dom';

const DetailPage = () => {
  const { category, index } = useParams();
  const dispatch = useDispatch();
  const lastDraw = useSelector((state) => state.draw.lastDraw);
  const [periods, setPeriods] = useState([]);

  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getLast());
  }, [dispatch]);

  useEffect(() => {
    if (lastDraw && lastDraw.data) {
      const totalPeriods = lastDraw.data.id;
      const periodsArray = Array.from({ length: totalPeriods }, (_, i) => totalPeriods - i);
      periodsArray.unshift(totalPeriods + 1);
      setPeriods(periodsArray);
      setLoading(false);
    }
  }, [lastDraw]);


  const handleTabChange = (key) => {
    setImageLoading(true); // Set loading to true before navigating
    navigate(`/detail/${category}/${key}`);
  };

  const imageUrl = `https://lotterymeicai.s3.amazonaws.com/${category}/${index}.jpg`;

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setImageLoading(false); // Set loading to false after image is loaded
  }, [imageUrl]);

  return (
    <div className="detail-page">
      {loading ? (
        <div className="loading-container">
          <SpinLoading color='default' />
        </div>
      ) : (
        <>
          <div className="year-selector">
            <Tabs
              defaultActiveKey={index}
              activeKey={index}
              onChange={handleTabChange}
            >
              {periods.map((period) => (
                <Tabs.Tab key={period.toString()} title={`${period}期`} />
              ))}
            </Tabs>
          </div>
          <div className="content">
            {imageLoading && (
              <div className="loading-container">
                <SpinLoading color='default' />
              </div>
            )}
            {!imageLoading && <img src={imageUrl} alt="Detail" />}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailPage;
