import { Button, Space, Swiper, Toast } from 'antd-mobile';
import Lottery from '../../components/lottery';
import ScrollingNotification from '../../components/ScrollingNotification';
import CountDownFlip from '../../components/CountDownFlip';
import { getNextDrawTime } from '../../redux/modules/draw';
import CountdownDisplay from '../../components/CountdownDisplay';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import logo from '../../assets/images/logo.png';
import xuanchuan from '../../assets/images/xuanchaun.jpg';
import xuanchuan1 from '../../assets/images/xuanchaun1.jpg';
import xuanchuan2 from '../../assets/images/xuanchaun2.jpg';
const Homepage = () => {
    const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac'];
    const dispatch = useDispatch();
    const nextDrawTime = useSelector((state) => state.draw.nextDrawTime);
    const [countdownTime, setCountdownTime] = useState(null);

    useEffect(() => {
      dispatch(getNextDrawTime());
    }, [dispatch]);

    useEffect(() => {
      if (nextDrawTime) {
        const nextDrawDate = new Date(nextDrawTime);
        const currentTime = new Date();
        const timeDifference = Math.floor((nextDrawDate - currentTime) / 1000);
        setCountdownTime(timeDifference);
      }
    }, [nextDrawTime]);

    
    const advertisementImages = [xuanchuan, xuanchuan1, xuanchuan2];
    const items = advertisementImages.map((image, index) => (
      <Swiper.Item key={index}>
        <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={image} alt={`Advertisement ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
        </div>
      </Swiper.Item>
    ));

    const popularSites = [
        { name: '强力球', url: 'https://www.powerball.com/' },
        { name: '超级百万', url: 'https://www.megamillions.com/' },
        { name: '梦幻五', url: 'https://www.calottery.com/draw-games/fantasy-5' },
        { name: '2by2', url: 'https://www.powerball.com/2by2' },
        { name: 'Cash4Life', url: 'https://www.mdlottery.com/games/cash4life/' },
        { name: '乐透', url: 'https://www.lottomax.com' },
        { name: '港彩', url: 'https://bet.hkjc.com/marksix/index.aspx?lang=ch' },
        { name: '澳彩', url: 'https://www.national-lottery.co.uk/' },
        { name: '英彩', url: 'https://www.zhihu.com' },
    ];

    const additionalButtons = [
      { label: '往期数据', url: '/history' },
      { label: '数据统计', url: '/statistics' },
      { label: '相关资料', url: '/lotteryImage' },
    ];

    return (
      <div>
        <div className='Advertiesment'>
          <Swiper loop autoplay>{items}</Swiper>
        </div>
        <Lottery period="2024165" />
        <CountdownDisplay />
        <div className="additional-buttons">
          {additionalButtons.map((button, index) => (
            <Link key={index} to={button.url} className="button-link">
              {button.label}
            </Link>
          ))}
        </div>
        <div className="links-section">
          {popularSites.map((site, index) => (
            <a key={index} href={site.url} className="site-link" target="_blank" rel="noopener noreferrer">
              {site.name}
            </a>
          ))}
        </div>
        <div className="footer-section">
            <img src={logo} alt="Logo" className="footer-logo" />
            <div className="footer-text">
            本网站专注于提供彩票相关信息，包括但不限于开奖结果、统计数据及彩票分析等。本网站不参与任何形式的彩票销售及赌博活动，亦不为彩票交易或者购买提供中介服务
            </div>
            <div className='copywrite'><strong>©2024 美彩国际</strong></div>
        </div>
      </div>
    );
}
export default Homepage;
