import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNextDrawTime } from '../redux/modules/draw';
import CountDownFlip from './CountDownFlip';
import './CountdownDisplay.scss'; 

const CountdownDisplay = () => {
  const dispatch = useDispatch();
  const nextDrawTime = useSelector((state) => state.draw.nextDrawTime);
  const [countdownTime, setCountdownTime] = useState(null);

  useEffect(() => {
    dispatch(getNextDrawTime());
  }, [dispatch]);

  useEffect(() => {
    if (nextDrawTime) {
      const nextDrawDate = new Date(nextDrawTime);
      const currentTime = new Date();
      const timeDifference = Math.floor((nextDrawDate - currentTime) / 1000);
      setCountdownTime(timeDifference);
    }
  }, [nextDrawTime]);
  
  return (
    <div className="countdown-container">
      <div className="title">下期开奖</div>
      {countdownTime !== null && <CountDownFlip className="countdown-flip small" initialTime={countdownTime} />}
    </div>
  );
};

export default CountdownDisplay;
